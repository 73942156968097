import styles from './styles.module.css';
import Chord from '@/components/Chord';
import useChordStore from '@/store/useChordStore.ts';

const ChordComposer = () => {
  const store = useChordStore();

  return (
    <div className={ styles.chordComposer }>
      <div className={ styles.list }>
        {
          store.chords.map((chord) => <Chord key={ chord.id } chord={ chord } />)
        }
      </div>
    </div>
  );
};

export default ChordComposer;