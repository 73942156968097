import { create } from 'zustand';
import { GuitarChord } from '@/types.ts';

const dummy: (GuitarChord & { id: string })[] = [
  { id: '1', key: 'A', label: 'A major' },
  { id: '2', key: 'B', label: 'B major' },
  { id: '3', key: 'C', label: 'C major' },
  { id: '4', key: 'D', label: 'D major' },
  { id: '5', key: 'E', label: 'E major' },
  { id: '6', key: 'F', label: 'F major' },
  { id: '7', key: 'G', label: 'G major' },
  { id: '8', key: 'A', label: 'A major' },
  { id: '9', key: 'B', label: 'B major' },
  { id: '10', key: 'C', label: 'C major' },
  { id: '11', key: 'D', label: 'D major' },
  { id: '12', key: 'E', label: 'E major' },
  { id: '13', key: 'F', label: 'F major' },
  { id: '14', key: 'G', label: 'G major' },
];

const useChordStore = create((set) => ({
  chords: [...dummy],
  addRandomChord() {
    set((state) => ({ chords: [...state.chords, { id: self.crypto.randomUUID(), key: 'A', label: 'A major' }] }));
  }
}));

export default useChordStore;