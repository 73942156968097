export const MeasureIcon = () => (
  <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <g data-name='Measure' id='Measure'>
      <path d='M8.75 6.25C7.37109 6.25 6.25 7.37109 6.25 8.75C6.25 10.1289 7.37109 11.25 8.75 11.25C10.1289 11.25 11.25 10.1289 11.25 8.75C11.25 7.37109 10.1289 6.25 8.75 6.25ZM24.375 15H14.8672C16.4883 13.4102 17.5 11.1992 17.5 8.75C17.5 3.91797 13.582 0 8.75 0C3.91797 0 0 3.91797 0 8.75C0 13.582 3.91797 17.5 8.75 17.5H24.375C24.7188 17.5 25 17.2188 25 16.875V15.625C25 15.2812 24.7188 15 24.375 15ZM8.75 12.5C6.67969 12.5 5 10.8203 5 8.75C5 6.67969 6.67969 5 8.75 5C10.8203 5 12.5 6.67969 12.5 8.75C12.5 10.8203 10.8203 12.5 8.75 12.5Z' fill='currentColor' />
    </g>
  </svg>
);

export const BookIcon = () => (
  <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <g data-name='Book' id='Book'>
      <path  d='M23.5339 0.00235704C21.1554 0.13734 16.428 0.628659 13.5095 2.41512C13.3082 2.53838 13.194 2.75757 13.194 2.98673V18.7797C13.194 19.281 13.7422 19.5978 14.2044 19.3652C17.207 17.8539 21.5495 17.4416 23.6966 17.3287C24.4297 17.2901 24.9996 16.7024 24.9996 15.998V1.33482C25 0.566159 24.3333 -0.0427818 23.5339 0.00235704ZM11.49 2.41512C8.57205 0.628659 3.84462 0.137774 1.46615 0.00235704C0.666667 -0.0427818 0 0.566159 0 1.33482V15.9984C0 16.7033 0.569879 17.291 1.30295 17.3292C3.45095 17.442 7.79557 17.8548 10.7982 19.3669C11.2591 19.5991 11.8056 19.2827 11.8056 18.7827V2.97892C11.8056 2.74932 11.6918 2.53882 11.49 2.41512Z'  fill='currentColor' />
    </g>
  </svg>
);

export const GridIcon = () => (
  <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <g data-name='Grid' id='Grid'>
      <path d='M4.6875 9.375H1.5625C0.699707 9.375 0 10.0747 0 10.9375V14.0625C0 14.9253 0.699707 15.625 1.5625 15.625H4.6875C5.55029 15.625 6.25 14.9253 6.25 14.0625V10.9375C6.25 10.0747 5.55029 9.375 4.6875 9.375ZM12.5 9.375H9.375C8.51221 9.375 7.8125 10.0747 7.8125 10.9375V14.0625C7.8125 14.9253 8.51221 15.625 9.375 15.625H12.5C13.3628 15.625 14.0625 14.9253 14.0625 14.0625V10.9375C14.0625 10.0747 13.3628 9.375 12.5 9.375ZM20.3125 9.375H17.1875C16.3247 9.375 15.625 10.0747 15.625 10.9375V14.0625C15.625 14.9253 16.3247 15.625 17.1875 15.625H20.3125C21.1753 15.625 21.875 14.9253 21.875 14.0625V10.9375C21.875 10.0747 21.1753 9.375 20.3125 9.375ZM4.6875 0H1.5625C0.699707 0 0 0.699707 0 1.5625V4.6875C0 5.55029 0.699707 6.25 1.5625 6.25H4.6875C5.55029 6.25 6.25 5.55029 6.25 4.6875V1.5625C6.25 0.699707 5.55029 0 4.6875 0ZM12.5 0H9.375C8.51221 0 7.8125 0.699707 7.8125 1.5625V4.6875C7.8125 5.55029 8.51221 6.25 9.375 6.25H12.5C13.3628 6.25 14.0625 5.55029 14.0625 4.6875V1.5625C14.0625 0.699707 13.3628 0 12.5 0ZM20.3125 0H17.1875C16.3247 0 15.625 0.699707 15.625 1.5625V4.6875C15.625 5.55029 16.3247 6.25 17.1875 6.25H20.3125C21.1753 6.25 21.875 5.55029 21.875 4.6875V1.5625C21.875 0.699707 21.1753 0 20.3125 0Z'  fill='currentColor' />
    </g>
  </svg>
);

export const SearchIcon = () => (
  <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <g data-name='Search' id='Search'>
      <path d='M24.6582 21.6162L19.79 16.748C19.5703 16.5283 19.2725 16.4062 18.96 16.4062H18.1641C19.5117 14.6826 20.3125 12.5146 20.3125 10.1562C20.3125 4.5459 15.7666 0 10.1562 0C4.5459 0 0 4.5459 0 10.1562C0 15.7666 4.5459 20.3125 10.1562 20.3125C12.5146 20.3125 14.6826 19.5117 16.4062 18.1641V18.96C16.4062 19.2725 16.5283 19.5703 16.748 19.79L21.6162 24.6582C22.0752 25.1172 22.8174 25.1172 23.2715 24.6582L24.6533 23.2764C25.1123 22.8174 25.1123 22.0752 24.6582 21.6162ZM10.1562 16.4062C6.7041 16.4062 3.90625 13.6133 3.90625 10.1562C3.90625 6.7041 6.69922 3.90625 10.1562 3.90625C13.6084 3.90625 16.4062 6.69922 16.4062 10.1562C16.4062 13.6084 13.6133 16.4062 10.1562 16.4062Z'  fill='currentColor' />
    </g>
  </svg>
);