import { ReactNode } from 'react';
import styles from './styles.module.css';
import ActionButton from '@/components/ActionButton';
import { BookIcon, GridIcon, MeasureIcon, SearchIcon } from '@/components/Icons';
import useChordStore from '@/store/useChordStore.ts';

type Props = {
  children: ReactNode;
}

const ChordActions = ({ children }: Props) => {
  const store = useChordStore();

  return (
    <div className={ styles.chordActions }>
      <div className={ styles.content }>
        {children}
      </div>
      <div className={ styles.topActions }>
        <ActionButton
          aria-label='size'
          icon={ <MeasureIcon /> }
          shortcut='^L'
          onClick={ () => store.addRandomChord() }
        />
        <ActionButton
          aria-label='home'
          icon={ <GridIcon /> }
          shortcut='^H'
        />
      </div>
      <div className={ styles.bottomActions }>
        <ActionButton
          aria-label='size'
          icon={ <SearchIcon /> }
          shortcut='^S'
        />
        <ActionButton
          aria-label='home'
          icon={ <BookIcon /> }
          shortcut='^B'
        />
      </div>
    </div>
  );
};

export default ChordActions;