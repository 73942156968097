import styles from './styles.module.css';
import ChordComposer from '@/components/ChordComposer';
import ChordActions from '@/components/ChordActions';

export default function HomePage() {
  return (
    <main className={ styles.homePage }>
      <ChordActions>
        <ChordComposer />
      </ChordActions>
    </main>
  );
}