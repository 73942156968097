import GuitarChordComponent from 'react-guitar-chord';
import styles from './styles.module.css';
import { GuitarChord } from '@/types.ts';

type Props = {
  chord: GuitarChord;
}

export default function Chord({ chord }: Props) {
  return (
    <div className={ styles.chord }>
      <h2 className={ styles.title }>{chord.label}</h2>
      <GuitarChordComponent chord={ chord.key } style={ { zIndex: -1 } } />
    </div>
  );
}